<template>
  <md-tabs :md-active-tab="current_tab" @md-changed="listenerChangeTab" >
    <md-tab id="tab-form" md-label="Contribuyente">
      <div class="md-layout">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <md-card >
            <form novalidate @submit.prevent="validateUser">
              <md-card-header>
                <md-card-header-text>
                  <div class="md-title" v-if="instance.id">Editar contribuyente</div>
                  <div class="md-title" v-if="!instance.id">Nuevo contribuyente</div>
                </md-card-header-text>
                <md-menu md-size="big" md-direction="bottom-end">
                  <md-button class="md-icon-button" @click="go_to('/')">
                    <md-icon>keyboard_backspace</md-icon>
                  </md-button>
                </md-menu>
              </md-card-header>
              <md-card-content>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('name')">
                      <label for="name">Nombre contribuyente</label>
                      <md-input v-model="instance.name" :disabled="sending" />
                      <span class="md-error"
                      v-if="!$v.instance.name.required">
                    EL nombre del contribuyente es requerida</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('razon_social')">
                      <label for="razon_social">Razón social</label>
                      <md-input  v-model="instance.razon_social" :disabled="sending" />
                      <span class="md-error"
                      v-if="!$v.instance.razon_social.required">La razón social es requerida</span>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('phone')">
                      <label for="phone">Teléfono</label>
                      <md-input name="phone" id="phone" type="number"
                      autocomplete="phone" v-model="instance.phone" :disabled="sending" />
                      <span class="md-error"
                      v-if="!$v.instance.phone.required">Un teléfono es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('nit')">
                      <label for="nit">NIT</label>
                      <md-input name="nit"
                      id="nit" v-model="instance.nit" type="text" :disabled="sending" />
                      <span class="md-error"
                      v-if="!$v.instance.nit.required">El NIT es requerido</span>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('address')">
                      <label for="address">Dirección</label>
                      <md-input type="text"
                      id="address" name="address" autocomplete="address"
                      v-model="instance.address" :disabled="sending" />
                      <span class="md-error"
                      v-if="!$v.instance.address.required">La dirección es requerida</span>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field >
                      <label for="email">Correo eléctronico</label>
                      <md-input type="email" v-model="instance.email" :disabled="sending"/>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item">
                    <md-field :class="getValidationClass('AfiliacionIVA')">
                      <label for="AfiliacionIVA">Afiliación IVA</label>
                      <md-select name="AfiliacionIVA" id="AfiliacionIVA"
                      v-model="instance.AfiliacionIVA" @md-selected="change_afilicion"
                      :disabled="sending">
                        <md-option value="GEN">GENERAL</md-option>
                        <md-option value="PEQ">PEQUEÑO CONTRIBUYENTE</md-option>
                      </md-select>
                      <span class="md-error">El regimen es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item">
                    <md-field :class="getValidationClass('tipo')">
                      <label for="tipo">Tipo empresa</label>
                      <md-select name="tipo" id="tipo"
                      v-model="instance.tipo"
                      :disabled="sending">
                        <md-option value="I">INDIVIDUAL</md-option>
                        <md-option value="J">JURIDICA</md-option>
                      </md-select>
                      <span class="md-error">Elege un tipo</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item">
                    <md-field :class="getValidationClass('porcentaje_IVA')">
                       <label for="porcentaje_IVA">porcentaje IVA</label>
                       <md-input type="number" min="1" pattern="^[0-9]+"
                       id="porcentaje_IVA" name="porcentaje_IVA" autocomplete="porcentaje_IVA"
                       v-model="instance.porcentaje_IVA" :disabled="sending" />
                       <span class="md-error">El porcentaje IVA es requerido</span>
                     </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('prefix_firma_representante_legal')">
                      <label for="prefix_firma_representante_legal">Profesión</label>
                      <md-input type="text" v-model="instance.prefix_firma_representante_legal"
                      :disabled="sending"/>
                      <span class="md-error">Profeción</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClass('representante_legal')">
                       <label for="representante_legal">Representante legal</label>
                       <md-input type="text"
                       id="representante_legal" name="representante_legal"
                       autocomplete="representante_legal"
                       v-model="instance.representante_legal"
                       :disabled="sending || instance.tipo === 'I'"/>
                       <span class="md-error">Representante legal</span>
                     </md-field>
                  </div>
                  <div class="md-layout-item">
                    <md-field :class="getValidationClass('tipo')">
                      <label for="tipo">Genero propietario</label>
                      <md-select name="tipo" id="tipo"
                      v-model="instance.genre_propietario"
                      :disabled="sending">
                        <md-option value="M">MASCULINO</md-option>
                        <md-option value="F">FEMENINO</md-option>
                      </md-select>
                      <span class="md-error">Elege un tipo</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item" v-if="instance.tipo === 'J'">
                    <md-field :class="getValidationClass('texto_pie_estados_financieros_juridica')">
                      <label for="texto_pie_estados_financieros_juridica">
                      Pie estados financieros</label>
                      <md-textarea
                      v-model="instance.texto_pie_estados_financieros_juridica"></md-textarea>
                      <span class="md-error">Elege un tipo</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item" v-if="instance.tipo === 'I'">
                    <md-field
                    :class="getValidationClass('texto_pie_estados_financieros_individual')">
                      <label for="texto_pie_estados_financieros_individual">
                      Pie estados financieros</label>
                      <md-textarea
                      v-model="instance.texto_pie_estados_financieros_individual"></md-textarea>
                      <span class="md-error">Elege un tipo</span>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter" v-if="!instance.id">
                  <div class="md-layout-item md-small-size-100">
                    <md-field >
                      <label for="period">Año fiscal</label>
                      <md-select v-model="instance.fiscal_year">
                      <md-option value="">----</md-option>
                      <md-option :value="item.id"
                        v-for="(item, key) in list_years"
                        :key="key">{{item.year}}</md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </md-card-content>
              <md-progress-bar md-mode="indeterminate" v-if="sending" />
              <md-card-actions>
                <md-button
                type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
              </md-card-actions>
            </form>
            <md-snackbar
              :md-active.sync="userSaved">{{ lastUser }} ha sido actualizado!</md-snackbar>
          </md-card>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
          <md-card>
              <md-card-header>
                <div class="md-title">Logotipo</div>
                <p class="md-caption">
                Click o arrastrar un elemento a esta sección para actualizar el logotipo</p>
              </md-card-header>
              <md-card-content>
              </md-card-content>
              <md-card-media-actions>
                <md-card-media v-if="instance.id">
                  <upload
                    :avatar_url="instance.logo_url"
                    :avatar_id="instance.logo"
                    v-on:get_id_avatar="set_logo"
                    :image_previo="true"></upload>
                </md-card-media>
              </md-card-media-actions>
          </md-card>
        </div>
      </div>
    </md-tab>
    <md-tab md-label="Cuentas bancarias" id="tab-cuentas">
      <div class="md-layout">
        <div class="md-layout-item md-size-60 md-small-size-100">
          <md-card>
              <md-card-header>
                <div class="md-title">Cuntas bancarias</div>
              </md-card-header>
              <md-card-content>
                <md-table  >
                  <md-table-row>
                    <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
                    <md-table-head>Nombres</md-table-head>
                    <md-table-head >Número</md-table-head>
                    <md-table-head >Tipo</md-table-head>
                    <md-table-head >Banco</md-table-head>
                  </md-table-row>
                  <md-table-row v-for="(item, index) in list_account" :key="item.id">
                    <md-table-cell class="md-xsmall-hide md-small-hide" >
                    {{index + 1}}</md-table-cell>
                    <md-table-cell  md-sort-by="name">{{ item.name }}</md-table-cell>
                    <md-table-cell>{{ item.account_number }}</md-table-cell>
                    <md-table-cell class="md-xsmall-hide md-small-hide">
                    {{ item.type_account_display }}</md-table-cell>
                    <md-table-cell>{{ item.bank_name }}</md-table-cell>
                    <md-table-cell >
                      <md-button class="md-icon-button" @click="to_edit(item, index)">
                        <md-icon class="md-size-1x" >edit</md-icon>
                      </md-button>
                    </md-table-cell>
                </md-table-row>
                </md-table>
                <md-progress-bar md-mode="indeterminate" v-if="sending" />
              </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-size-40 md-small-size-100">
          <md-card >
            <form novalidate @submit.prevent="validateAccount">
              <md-card-header>
                <md-card-header-text>
                  <div class="md-title" v-if="account_obj.id">Editar cuenta bancaria</div>
                  <div class="md-title" v-if="!account_obj.id">Nueva cuenta bancaria</div>
                </md-card-header-text>
                <md-menu md-size="big" title="Nuevo" md-direction="bottom-end">
                  <md-button class="md-icon-button" @click="reset_account_form()">
                    <md-icon>add</md-icon>
                  </md-button>
                </md-menu>
                <md-menu md-size="big" title="Regresar" md-direction="bottom-end">
                  <md-button class="md-icon-button" @click="go_to('/')">
                    <md-icon>keyboard_backspace</md-icon>
                  </md-button>
                </md-menu>
              </md-card-header>
              <md-card-content>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClassAccount('name')">
                      <label for="name">Nombre cuenta bancaria</label>
                      <md-input ref="name_account" v-model="account_obj.name" :disabled="sending" />
                      <span class="md-error"
                      v-if="!$v.account_obj.name.required">
                    EL nombre de la cuenta bancaria es requerida</span>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-size-50">
                    <md-field :class="getValidationClassAccount('account_number')">
                      <label for="account_number">Número</label>
                      <md-input
                      v-model="account_obj.account_number" :disabled="sending" />
                      <span class="md-error"
                      v-if="!$v.account_obj.account_number.required">
                    El numero de cuenta es requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50">
                    <md-field :class="getValidationClassAccount('type_account')">
                      <label for="type_account">Tipo cuenta bancaria</label>
                    <md-select v-model="account_obj.type_account"
                      :disabled="sending"
                    >
                    <md-option :value="item.value"
                      v-for="(item, key) in choosen_type_account"
                      :key="key">{{item.text}}</md-option>
                    </md-select>
                    <span class="md-error"
                      v-if="!$v.account_obj.type_account.required">
                      El tipo de la cuenta requerido</span>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-field :class="getValidationClassAccount('saldo_inicial')">
                      <label for="saldo_inicial">Saldo inicial</label>
                      <md-input v-model="account_obj.saldo_inicial"
                      type="number" :disabled="sending" />
                      <span class="md-error"
                      v-if="!$v.account_obj.saldo_inicial.required">
                    El saldo_inicial es requerido</span>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-small-size-100">
                    <md-autocomplete autocomplete="off"
                      :class="getValidationClassAccount('bank')"
                      id="bank_name" class="autocomplete"
                      :disabled="sending"
                      v-model="account_obj.bank_name"
                      md-input-name="bank"
                      :md-options="list_banks"
                      @md-selected="set_bank"
                      @md-changed="get_list_banks" >
                    <label for="bank_name">Banco {{account_obj.bank_name}}</label>
                    <template
                      slot="md-autocomplete-item" slot-scope="{ item, term }">
                      <md-highlight-text :md-term="term">
                        {{ item.name }}</md-highlight-text>
                    </template>
                    <span class="md-error"
                      v-if="!$v.account_obj.bank.required">
                      La cuenta contable es requerida</span>
                    </md-autocomplete>
                  </div>
                </div>
              </md-card-content>
              <md-progress-bar md-mode="indeterminate" v-if="sending" />
              <md-card-actions>
                <md-button
                type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
              </md-card-actions>
            </form>
          </md-card>
        </div>
        <md-snackbar
          :md-active.sync="alert">{{ message }}</md-snackbar>
        <br>
        <br>
      </div>
    </md-tab>
  </md-tabs>
</template>
<script>
/* eslint-disable */
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'

export default {
  name: 'Company',
  created(){
    this.instance.id = this.$route.params.id
    this.retrieveInstance();
    if (!this.instance.id){
      this.get_list_years()
    }
  },
  mixins: [validationMixin],
  components: {
    upload: () => import('../components/Upload.vue'),
  },
  data() {
    return {
      current_tab: 'tab-form',
      logo_url: '',
      message: null,
      alert: false,
      base: process.env.VUE_APP_BASE_URL,
      list_banks: [],
      list_account: [],
      instance: {
        name: null,
        address: null,
        phone: null,
        nit: null,
        AfiliacionIVA: null,
        porcentaje_IVA: null,
        razon_social: null,
      },
      account_obj: {
        bank: null,
        name: null,
        type_account: null,
        saldo_inicial: null,
        account_number: null,
      },
      choosen_type_account: [
        {value:'S', text: 'AHORROS'},
        {value:'M', text: 'MONETARIA'},
        {value:'C', text: 'CHEQUES'},
      ],
      url: 'company/',
      url_banks: 'banks/',
      url_account_bank: 'account_bank/',
      url_fiscal_year: 'fiscal_year',
      userSaved: false,
      sending: false,
      lastUser: null,
      list_years: [],
    };
  },
  validations: {
    account_obj: {
      name: {
        required,
      },
      type_account: {
        required,
      },
      saldo_inicial: {
        required,
      },
      account_number: {
        required,
      },
      bank: {
        required,
      },
    },
    instance: {
      name: {
        required,
      },
      razon_social: {
        required,
      },
      porcentaje_IVA: {
        required,
      },
      AfiliacionIVA: {
        required,
      },
      address: {
        required
      },
      phone: {
        required,
      },
      nit: {
        required,
      },
      email: {
        email
      },
    },
  },
  methods: {
    get_list_years() {
      const payload = {};
      const self = this;
      this.sendRequest(this.url_fiscal_year, payload).then((data) => {
        self.list_years = data.data;
      }).catch((error) => {        
        self.handlerError(error);
      });
      return null;
    },
    change_afilicion(data) {
      if (data==='PEQ') {
        this.instance.porcentaje_IVA = 5
      }
      if (data==='GEN') {
        this.instance.porcentaje_IVA = 12
      }
    },
    listenerChangeTab(tab_id){
      this.current_tab = tab_id

      if (tab_id==='tab-cuentas' && this.list_account.length === 0){
        this.get_list_account()
      }
    },
    reset_account_form(){
      this.account_obj = {
        bank: null,
        name: null,
        type_account: null,
        saldo_inicial: null,
        account_number: null,
      }
      this.$refs.name_account.$el.focus()
    },
    go_to(url) {
      this.$router.push(url);
    },
    to_edit(instance, index) {
      this.account_obj = instance
      this.$refs.name_account.$el.focus()
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    set_bank(obj) {  
      this.account_obj.bank_name = obj.name;
      this.account_obj.bank = obj.id;
    },
    get_list_banks(searchTerm) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
        payload.accountSearch = "1"
      }
      filter: "True"
      const self = this;
      this.sendRequest(this.url_banks, payload ).then((data) => {
        self.list_banks = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      self.$swal.fire('Error!', result, 'error');
      return false;
    },
    set_logo(document_id) {
      let payload = {}
      payload.change_logo = 'True'
      payload.id = this.instance.id
      payload.url = document_id
      const self = this;
        this.retrieveRequest('company/', payload).then((data) => {
          self.instance = data.data
          self.$swal.fire('Actualizado', 'Actualización exitosa.', 'success');
        }).catch((error) =>{
          this.handlerError(error)
      });
    },
    get_list_account() {
      const payload = {};
      const self = this;
      payload.company = this.instance.id

      if (!this.instance.id) {
        self.message = 'No se puede obtener cuentas bancarias aún.'
        self.alert = true
      }
      this.sendRequest(this.url_account_bank, payload).then((data) => {
        self.list_account = data.data;
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    getValidationClass (fieldName) {
      const field = this.$v.instance[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    getValidationClassAccount (fieldName) {
      const field = this.$v.account_obj[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm () {
      this.$v.instance.$reset()
    },
    clearFormAccount () {
      this.$v.account_obj.$reset()
    },
    validateUser () {
      this.$v.instance.$touch()
      if (!this.$v.instance.$invalid) {
        this.saveOrUpdate()
      }
    },
    validateAccount () {
      this.$v.account_obj.$touch()
      if (!this.$v.account_obj.$invalid) {
        this.saveOrUpdateAccount()
        return 
      }
      // this.clearFormAccount()
    },
    saveOrUpdateAccount () {
      if (!this.account_obj.name) {
        this.$swal.fire('Error!', 'El nombre requerido.', 'error')
        return false
      }
      const payload = this.account_obj
      const self = this
      this.sending = true
      if (this.account_obj.id) {
        this.putRequest(this.url_account_bank, payload).then(function (data) {
          self.message = `La cuenta bancaria: ${self.account_obj.name} ha sido actualizada.`
          self.alert = true
          self.sending = false
          self.account_obj = data.data
          self.clearFormAccount()
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      if (!this.instance.id) {
        self.$swal.fire('Error!', 'El contribuyente es requerido', 'error')
        self.sending = false
        return false;
      }
      payload.company = this.instance.id
      this.postRequest(this.url_account_bank, payload).then(function (data) {
        self.message = `La cuenta bancaria: ${self.account_obj.name} ha sido agregada.`
        self.alert = true
        self.sending = false
        self.account_obj = data.data
        self.list_account.unshift(data.data)
        self.clearFormAccount()
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    saveOrUpdate () {
      if (!this.instance.name) {
        this.$swal.fire('Error!', 'El nombre requerido.', 'error')
        return false
      }
      const payload = this.instance
      const self = this
      this.sending = true
      if (this.instance.id) {
        this.putRequest(this.url, payload).then(function (data) {
          self.lastUser = `${self.instance.name}`
          self.userSaved = true
          self.sending = false
          self.instance = data.data
          self.clearForm()
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      this.postRequest(this.url, payload).then(function (data) {
        self.lastUser = `${self.instance.name}`
        self.userSaved = true
        self.sending = false
        self.clearForm()
        self.instance = data.data
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    async retrieveInstance () {
      if (!this.instance.id) return false
      const self = this
      const payload = {
        id: this.instance.id,
        basic_serializer: 'True'
      }
      await this.retrieveRequest('company/', payload).then(function (data) {
        self.instance = data.data
      }).catch(function (error) {
        self.handlerError(error)
      })
    },
    async sendRequest(url, payload) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async postRequest (url, payload) {
      const result = await this.$http.post(url, payload)
      return result
    },
    async putRequest (url, payload) {
      const result = await this.$http.put(`${url}${payload.id}/`, payload)
      return result
    },
    async retrieveRequest (url, _payload) {
      const result = await this.$http.get(url + _payload.id + '/', { params: _payload })
      return result
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #424242 inset;
  }
</style>
